var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{attrs:{"striped":"","responsive":"sm"}},[_c('b-thead',[_c('b-th',[_vm._v(_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", false, "Niveles de Logro" ))+" ")]),_c('b-th',[_vm._v("Descripción")]),_c('b-th',{staticStyle:{"width":"80px"}},[_vm._v(_vm._s(_vm.RubricPreset && _vm.RubricPreset.show_percentage_sign ? "% Logro" : "Puntaje"))]),(_vm.allows_crud)?_c('b-th',{staticClass:"text-center",staticStyle:{"width":"30px"}},[(_vm.allows_crud)?_c('ButtonSmall',{attrs:{"tooltip_text":`Crear ${_vm.$getVisibleNames(
            'evaluations.observationachievement',
            false,
            'Nivel de Logro'
          )}`,"text_button":``,"click_button":() =>
              _vm.$bvModal.show(
                `created-rubric_preset_unit-modal-${_vm.RubricPreset.id}`
              ),"icon":'plus',"variant":'none'}}):_vm._e()],1):_vm._e()],1),_c('b-tbody',[_vm._l((_vm.rubricPresetList),function(rubric_preset_unit){return [_c('b-tr',{key:`${_vm.RubricPreset.id}-${rubric_preset_unit.id}`},[_c('b-td',{staticClass:"pl-3 text-justify align-text-top"},[_vm._v(" "+_vm._s(rubric_preset_unit.title)+" ")]),_c('b-td',[_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(rubric_preset_unit.description)}})]),_c('b-td',[_vm._v(" "+_vm._s(rubric_preset_unit.score)+" ")]),(_vm.allows_crud)?_c('b-td',[_c('div',{staticClass:"d-flex p-1"},[_c('ButtonSmall',{attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Nivel de Logro'
                )}`,"text_button":``,"click_button":() =>
                    _vm.$bvModal.show(
                      `edit-rubric_preset_unit-modal-${rubric_preset_unit.id}`
                    ),"icon":'square'}}),_c('ButtonSmall',{attrs:{"tooltip_text":`Crear ${_vm.$getVisibleNames(
                  'evaluations.observationachievement',
                  false,
                  'Nivel de Logro'
                )}`,"text_button":``,"click_button":() => _vm.askForDeleteRubricPresetUnit(rubric_preset_unit.id),"icon":'trash'}})],1),_c('b-modal',{attrs:{"id":`edit-rubric_preset_unit-modal-${rubric_preset_unit.id}`,"title":`Editar ${_vm.$getVisibleNames(
                'evaluations.observationachievement',
                false,
                'Niveles de Logro'
              )}`,"size":"md","hide-footer":""}},[_c('RubricPresetUnitForm',{attrs:{"RubricPreset":_vm.RubricPreset,"RubricPresetUnit":rubric_preset_unit},on:{"updated":_vm.slotUpdatedRubricPresetUnit}})],1)],1):_vm._e()],1)]})],2),(_vm.rubricPresetList.length == 0)?[_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":_vm.allows_crud ? 4 : 2}},[_vm._v("No hay datos que mostrar.")])])])]:_vm._e()],2),_c('b-modal',{attrs:{"id":`created-rubric_preset_unit-modal-${_vm.RubricPreset.id}`,"title":`Editar ${_vm.$getVisibleNames(
      'evaluations.observationachievement',
      false,
      'Niveles de Logro'
    )}`,"size":"md","hide-footer":""}},[_c('RubricPresetUnitForm',{attrs:{"RubricPreset":_vm.RubricPreset},on:{"created":function($event){return _vm.$bvModal.hide(`created-rubric_preset_unit-modal-${_vm.RubricPreset.id}`)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }